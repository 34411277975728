import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { cn } from 'utils/cn'
import { CustomText } from './custom_text'

type Props = {
  children: ReactNode
  currentPosition?: number
  height: number
  elementsRef: React.MutableRefObject<React.RefObject<HTMLDivElement>[]>
  showActionButtons?: boolean
  isScrollEnabled?: boolean
  onCurrentPositionChanged?: (currentPosition: number) => void
}

/**
 * Renders a form that holds its children in a list and scrolls through
 * them on command.
 *
 * In order to make this work properly, make sure all children have a
 * height of 100%.
 *
 * @param children list of children to render.
 * @param currentPosition index of the child that should be currently visible.
 * @param height height of the form container.
 * @param elementsRef reference of each child in the [children] list.
 * @param showActionButtons controls whether "Previous" and "Next" buttons
 * should be visible.
 * @param isScrollEnabled controls whether users should be able to use
 * scroll to move between children.
 * @param onCurrentPositionChanged callback function triggered whenver
 * the current position is updated.
 */
export function ConversationalForm({
  children,
  currentPosition = 0,
  height,
  elementsRef,
  showActionButtons = true,
  isScrollEnabled = true,
  onCurrentPositionChanged
}: Props) {
  const docRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)

  const [_currentPosition, setCurrentPosition] = useState(currentPosition)
  const [previousPosition, setPreviousPosition] = useState(0)
  const [isScrolling, setIsScrolling] = useState(false)

  const updateCurrentPosition = useCallback(
    (newPosition: number) => {
      // If the user is still scrolling or newPosition is outside of the supported
      // range, we just ignore this call
      if (
        isScrolling ||
        newPosition < 0 ||
        newPosition >= elementsRef.current.length
      ) {
        return
      }

      // Calculates the amount to scroll the component by.
      let scrollBy

      if (_currentPosition > newPosition) {
        scrollBy = 0 - height
      } else {
        scrollBy = height
      }

      scrollRef.current?.scrollBy({
        top: scrollBy,
        left: 0,
        behavior: 'smooth'
      })

      setPreviousPosition(_currentPosition)
      setCurrentPosition(newPosition)

      onCurrentPositionChanged?.(newPosition)
    },
    [
      _currentPosition,
      elementsRef,
      height,
      isScrolling,
      onCurrentPositionChanged
    ]
  )

  // Handles scrolling in this component's main container.
  const handleScroll = useCallback(
    (event?: React.UIEvent<HTMLDivElement, UIEvent>) => {
      event?.stopPropagation()
      event?.nativeEvent.stopImmediatePropagation()
      event?.preventDefault()

      setIsScrolling(true)

      const scrollTop = scrollRef.current!.scrollTop / height
      const rounded = Math.floor(scrollTop)
      const opacity = scrollTop - rounded

      let toFullOpacity = opacity === 0 ? 1 : opacity
      let toNoOpacity = 1 - opacity

      if (_currentPosition > previousPosition) {
        maybeSetOpacity(
          `${toNoOpacity}`,
          elementsRef.current[_currentPosition]?.current
        )

        maybeSetOpacity(
          `${toFullOpacity}`,
          elementsRef.current[previousPosition]?.current
        )
      } else {
        maybeSetOpacity(
          `${toFullOpacity}`,
          elementsRef.current[_currentPosition]?.current
        )

        maybeSetOpacity(
          `${toNoOpacity}`,
          elementsRef.current[previousPosition]?.current
        )
      }
    },
    [_currentPosition, elementsRef, height, previousPosition]
  )

  // Hook to add listeners to the scrolls events we need.
  // If [isScrollEnabled] is false, this hook is just ignored.
  useEffect(() => {
    if (currentPosition === 1 && currentPosition !== _currentPosition) {
      updateCurrentPosition(currentPosition)
    }

    if (!isScrollEnabled) return

    function handleKeydown(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        updateCurrentPosition(_currentPosition + 1)
      }
    }

    window.addEventListener('keydown', handleKeydown)

    function updateIsScrolling() {
      setIsScrolling(false)
    }

    scrollRef.current?.addEventListener('scrollend', updateIsScrolling)

    // This is only called when the component is destroyed to remove all listeners
    return () => {
      window.removeEventListener('keydown', handleKeydown)
      window.removeEventListener('scrollend', updateIsScrolling)
    }
  }, [_currentPosition, handleScroll, isScrollEnabled, updateCurrentPosition])

  function maybeSetOpacity(opacity: string, current?: HTMLDivElement | null) {
    // If for whatever reason when getting the current element from a ref it
    // returns a falsy value, we don't change anything.
    if (current) {
      current.style.opacity = opacity
    }
  }

  const canShowPreviousButton = _currentPosition > 0

  const canShowNextButton = _currentPosition < elementsRef.current.length - 1

  return (
    <div className="ConversationalForm" ref={docRef}>
      <div
        className="content"
        ref={scrollRef}
        onScroll={handleScroll}
        style={{ height: height }}
      >
        {children}
      </div>

      {showActionButtons && (
        <div className={cn('actions', !canShowNextButton ? 'lastPage' : '')}>
          <div>
            {canShowPreviousButton && (
              <button
                onClick={(event) => {
                  event.preventDefault()

                  updateCurrentPosition(_currentPosition - 1)
                }}
              >
                Previous
              </button>
            )}
          </div>
          <div>
            {canShowNextButton && (
              <div className="nextBtnContainer">
                <button
                  onClick={(event) => {
                    event.preventDefault()

                    updateCurrentPosition(_currentPosition + 1)
                  }}
                >
                  Next
                </button>
                <CustomText>(or press Enter)</CustomText>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="pageIndicator">
        <p>
          <span>{_currentPosition + 1}</span> of{' '}
          <span>{elementsRef.current.length}</span>
        </p>
      </div>
    </div>
  )
}
